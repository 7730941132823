import Api from "../Api"
export default{
    getActivos(){    
    return  Api() .get('/productos/active')
    },
    getConsumos(id){
        return Api().get('/admision/productos/all/'+id)
    }
}

